.nextVideosSliderWrapper {
  max-width: calc(100vw - 79px);
  margin: 0 auto;
}
.nextVideosSliderWrapper .slick-prev:before,
.nextVideosSliderWrapper .slick-next:before {
  color: #052b62 !important;
}

.nextVideosSliderWrapper .slick-slider {
  width: 100%;
  overflow: visible !important;
  height: min-content;
}
.nextVideosSliderWrapper .slick-list {
  max-width: 1200px !important;
  position: relative;
  display: block !important;
  margin: auto !important;
  padding-bottom: 2px !important;
}
.nextVideosSliderWrapper .slick-slide > div {
  width: auto;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.nextVideosSliderWrapper .paddingTop {
  padding-top: 100px !important;
}
/* For the demo */
.nextVideosSliderWrapper .slick-prev,
.nextVideosSliderWrapper .slick-next {
  z-index: 999 !important;
}
.nextVideosSliderWrapper .slick-prev {
  left: 0px !important;
}
.nextVideosSliderWrapper .slick-next {
  right: 5px !important;
}
.nextVideosSliderWrapper .slick-dots {
  position: static !important;
}
.nextVideosSliderWrapper .slick-prev:before {
  font-size: 26px !important;
  opacity: 1 !important;
}

.nextVideosSliderWrapper .slick-next:before {
  font-size: 26px !important;
  opacity: 1 !important;
}

@media screen and (max-width: 1280px) {
  .nextVideosSliderWrapper .slick-prev {
    left: -24px !important;
  }
  .nextVideosSliderWrapper .slick-next {
    right: -24px !important;
  }
}
@media screen and (max-width: 1024px) {
  .nextVideosSliderWrapper .slick-prev {
    left: -20px !important;
  }
  .nextVideosSliderWrapper .slick-next {
    right: -20px !important;
  }
}
@media screen and (max-width: 900px) {
  .nextVideosSliderWrapper .slick-prev {
    left: 0px !important;
  }
  .nextVideosSliderWrapper .slick-next {
    right: 5px !important;
  }
}

@media screen and (max-width: 600px) {
  .nextVideosSliderWrapper .slick-slide > div {
    width: auto;
    padding: 0;
  }
}

@media screen and (max-width: 450px) {
  .nextVideosSliderWrapper .slick-prev {
    left: -20px !important;
  }
  .nextVideosSliderWrapper .slick-next {
    right: -20px !important;
  }
}
