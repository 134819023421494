.btnContainer {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.selectIcon {
  height: 36px;
  width: 36px;
}
.ratingIcon {
  margin: 0 1.111vw 0 1.111vw;
  cursor: pointer;
  height: 24px;
  width: 24px;
}
.imageContainer {
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.12);
}
.imageWrapperSelected {
  border: 1px solid black;
  border-radius: 8px;
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.12);
}
.imageWrapperUnselected {
  border: 1px solid #e3e3e3;
  border-radius: 8px;
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.12);
}

.formContainer {
  padding: 1.667vw;
}
.skillsFormHeading {
  color: #0056a3;
  font-family: "Poppins", sans-serif;
  font-size: 24px !important;
  font-style: normal;
  font-weight: 700 !important;
}

.skillNameText {
  color: #3b3b3b;
  font-family: "Poppins", sans-serif;
  font-size: 18px !important;
  font-style: normal;
  font-weight: 600 !important;
  line-height: normal;
}

.skillsFormBody {
  padding: 14px;
  border: 0.2px solid #e3e3e3;
  border-radius: 8px;
  display: flex;
  align-items: center;
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.12);
}

.skillRatingContainer,.behaviourRatingContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.textInputField
  > .MuiInputBase-root.MuiInput-root.MuiInput-underline.MuiInputBase-formControl.MuiInput-formControl.MuiInputBase-multiline {
  margin-top: 0.8vw;
  height: 3.889vw;
  border: 0.2px solid rgba(211, 220, 230, 1);
  border-radius: 8px;
  display: flex;
  align-items: center;
  box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.2);
  font-size: 1.11vw;
}
.textInputField
  > .MuiInputBase-root.MuiInput-root.MuiInput-underline.MuiInputBase-formControl.MuiInput-formControl.MuiInputBase-multiline::before {
  border: none;
}

.textInputField.textInputFeedbackField
  > .MuiInputBase-root.MuiInput-root.MuiInput-underline.MuiInputBase-formControl.MuiInput-formControl.MuiInputBase-multiline {
  margin-top: 0.8vw;
  height: unset;
  border: 0.2px solid rgba(211, 220, 230, 1);
  border-radius: 8px;
  display: flex;
  align-items: center;
  box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.2);
  font-size: 1.11vw;
}
.textInputField
  > .MuiInputBase-root.MuiInput-root.MuiInput-underline.MuiInputBase-formControl.MuiInput-formControl.MuiInputBase-multiline
  > textarea {
  overflow: hidden;
  padding-left: 0.5vw;
}

.textInputField > label {
  font-size: 1.667vw !important;
  color: black;
  top: -0.833vw;
}

.inputLabel {
  font-size: 1.667vw !important;
  color: black;
}

.textInputContainer.MuiGrid-root.textInputContainer.MuiGrid-item {
  font-weight: 500;
}
.ModalContainer {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #ffffff;
  box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.2);
  padding: 4;
  outline: none;
  border-radius: 4px;
  width: 49.653vw;
}

.successOkayBtn.MuiButtonBase-root.MuiButton-root.MuiButton-text {
  padding: 0;
  background: #0056a3;
  height: 3.333vw;
  width: 12.153vw;
  text-transform: none;
  /* margin-top: 6.806vw;
        margin-bottom: 2.708vw;
        margin: 6.806vw 2.708vw 2.708vw auto; */
  font-size: 0.972vw;
  color: fff;
  /* "&:hover": {
          background: "#010E36",
          opacity: 0.8
        } */
}
.successBtnTitle {
  color: #0056a3;
  font-weight: 700 !important;
  font-size: 2.222vw !important;
  text-align: center;
  margin-top: 3.333vw !important;
  margin-bottom: 2vw !important;
}
.successDescription {
  width: 36.181vw;
  text-align: center;
  margin-top: 2.708vw !important;
  font-size: 1.667vw !important;
}
.publishAlertDescription {
  font-size: 1.389vw !important;
  width: 36.181vw;
  text-align: center;
  margin-top: 2.708vw !important;
}

.successImage {
  height: 20.208vw;
  width: 20.208vw;
}
.publishedSuccessImage {
  height: 12.083vw;
  width: 12.639vw;
}
.publishedAlertImage {
  height: 12.083vw;
  width: 12.083vw;
}

.successImageContainer {
  text-align: center;
}

.themeContainerWrapper.MuiGrid-spacing-xs-3 {
  width: 100%;
  margin: unset;
}

.themeImageItem {
  width: 100%;
  border-radius: 8px;
  border: 1px solid #e3e3e3;
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.12);
}

.themeImageWrapper {
  display: block;
  padding: 16px 16px 0 16px;
}

.editIconsSr.makeStyles-editIconsContainer-93 {
  text-align: left;
}

.themeTitle {
  font-size: 20px;
  margin: unset;
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  color: #0056a3;
}

@media (min-width: 960px) and (max-width: 1280px) {
  .makeStyles-headerCellStudentReport-87.MuiGrid-item {
    font-size: 1.11vw !important;
  }
}
@media (max-width: 767px) {
  .skillsformheading {
    font-size: 20px !important;
  }

  .skillRatingContainer {
    flex-direction: column;
    align-items: flex-start;
  }
}

@media (max-width: 767px) {
  .skillNameText {
    font-size: 14px !important;
    margin-bottom: 10px !important;
  }
}

.ErrorSnackbarSr > .MuiSnackbarContent-root {
  background-color: white;
  color: red;
  min-width: unset !important;
  font-size: 2vw;
  max-width: 70vw;
}
