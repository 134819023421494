.formContainer {
  padding: 1.667vw;
}

.skillNameTxt {
  display: block;
}
.skillNameTxt.MuiTypography-body1 {
  font-size: 20px;
  margin-bottom: 7px;
  font-weight: 600;
  color: #3b3b3b;
}

.textInputField {
  width: 100%;
}

.textInputField.MuiInputBase-root {
  font-size: 16px;
}
.textInputField > .MuiInputBase-input {
  height: 56px;
  padding: 0 0 0 20px;
  border: 0.2px solid rgba(211, 220, 230, 1);
  border-radius: 4px;
  display: flex;
  align-items: center;
}

.PirDropDown.makeStyles-dropDownStdRpt-83.MuiOutlinedInput-root {
  height: 3.889vw;
  border-radius: 4px !important;
}

.textInputField.textInputOverviewField
  > .MuiInputBase-root.MuiInput-root.MuiInput-underline.MuiInputBase-formControl.MuiInput-formControl.MuiInputBase-multiline {
  margin-top: 0.8vw;
  height: unset;
  border: 0.2px solid rgba(211, 220, 230, 1);
  border-radius: 4px;
  display: flex;
  align-items: center;
  box-shadow: none;
  font-size: 16px;
}

.textInputField.textInputOverviewField
  > .MuiInputBase-root.MuiInput-root.MuiInput-underline.MuiInputBase-formControl.MuiInput-formControl.MuiInputBase-multiline::after {
  border-bottom: none;
}

.addImpactStoryBtn {
  display: block;
  margin-left: auto;
  height: 50px;
  width: 50px;
  cursor: pointer;
}

.subTitleContainer {
  background-color: #0056a3;
  font-size: 18px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  color: white;
  padding: 10px 20px;
}

.subTitleRight {
  margin-left: auto;
}
.skillNameText {
  margin-right: 10px;
  font-size: 18px !important;
}

.spanElement {
  margin: 0 1.8vw;
}

.btnContainer {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.ModalContainerImpactReport {
  background-color: #ffffff;
  box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.2);
  padding: 4;
  outline: none;
  border-radius: 4px;
  width: 49.653vw;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.successBtn.MuiButtonBase-root.MuiButton-root.MuiButton-text {
  text-transform: none;
  /* margin-top: 6.806vw;
    margin-bottom: 2.708vw;
    margin: 6.806vw 2.708vw 2.708vw auto; */
  font-size: 0.972vw;
  color: fff;
  /* "&:hover": {
      background: "#010E36",
      opacity: 0.8
    } */
  padding: 0;
  background: #0056a3;
  height: 3.333vw;
  width: 12.153vw;
}
.btnTitle {
  color: #0056a3;
  font-weight: 700 !important;
  font-size: 2.222vw !important;
  text-align: center;
  margin-top: 3.333vw !important;
  margin-bottom: 2vw !important;
}
.successDescription {
  width: 36.181vw;
  text-align: center;
  margin-top: 2.708vw !important;
  font-size: 1.667vw !important;
}

.ErrorSnackbar > .MuiSnackbarContent-root {
  background-color: white;
  color: red;
  min-width: unset !important;
}
@media (max-width: 767px) {
  .tableWrapper {
    max-width: calc(100vw - 137px);
    overflow-x: auto;
  }
  .header,
  .row {
    min-width: 767px;
  }
}
@media (max-width: 600px) {
  .tableWrapper {
    max-width: calc(100vw - 91px);
  }
  .skillNameTxt.MuiTypography-body1 {
    font-size: 14px;
  }
  .skillNameText {
    font-size: 14px !important;
  }
}
