.rbc-toolbar {
    display: none;
}

.rbc-header {
    border-bottom: none;
}

.rbc-date-cell {
    font-family: 'Poppins', sans-serif !important;
    margin-top: 14px;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    text-align: center;
    color: #3B3B3B;
    mix-blend-mode: normal;
    opacity: 0.74;
}

.rbc-allday-cell {
    display: none;
}

.rbc-header.rbc-today div {
    background: linear-gradient(131deg, #B3DBFF -33.84%, #4B90CD 19.41%, #0156A2 95.54%);
    border: none;
}
.rbc-time-header-cell-single-day {
    padding-left: 10px;
}

.rbc-time-header-cell-single-day {
    display: flex;
}

.rbc-time-header-cell-single-day button {
    display: flex;
}

.rbc-today {
    background: none;
}

.rbc-header.rbc-today div>span {
    color: white;
}

.rbc-event {
    background-color: transparent;
}

.react-calendar__navigation__next2-button {
    display: none;
}

.react-calendar__navigation__prev2-button {
    display: none;
}

.rbc-event-label {
    display: none;
}

.react-calendar {
    padding: 16px;
    background: #FDFDFD;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.122705);
    border-radius: 10px;
}

.react-calendar__navigation {
    margin-bottom: 0;
    border-bottom: 2px solid #0056A3;
    padding: 10px 0 18px 0;
    height: fit-content;
    background-color: #FDFDFD;
}

.react-calendar__month-view__weekdays {
    font-family: 'Poppins', sans-serif !important;
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 22px;
    text-align: center;
    color: #0056A3;
}

.react-calendar__month-view__weekdays abbr {
    text-decoration: none;
}

.react-calendar__month-view__days__day {
    font-family: 'Poppins', sans-serif !important;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #3F526D;
    mix-blend-mode: normal;
    opacity: 0.7;
}

.react-calendar__month-view__days__day--neighboringMonth {
    opacity: 0.3 !important;
}

.react-calendar__navigation__label__labelText--from {
    font-family: 'Poppins', sans-serif !important;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    /* identical to box height */

    text-align: center;

    color: #0056A3;
}

.react-calendar__tile--active {
    background: linear-gradient(131deg, #B3DBFF -33.84%, #4B90CD 19.41%, #0156A2 95.54%);
    border-radius: 4px;
    color: #FFFFFF;
    opacity: 1 !important;

}

.react-calendar__navigation__prev-button:hover {
    background-color: transparent;
}