body {
  margin: 0;
  padding:0;
  border: none;
}
#forPuzzle {
  border:none;
  left:0px;
  top:0px;
  width: 100% !important;
}
#forPuzzle div canvas{
  width: 100% !important;
}
#divmenu {
  position:absolute;
  top:5px;
  left:15px;
  width:0px;
  height:0px;
  cursor:default;
  z-index:50001;
  border-radius:5px;
}
#divmenu.open {
  width:120px;
  height:100px;
  background-color:rgba(255,255,180,0.7);
  z-index:50001;
}

#divmenu .title {
  font-family: Verdana, "Bitstream Vera Sans", "Lucida Sans", sans-serif;
  font-weight:bold;
  font-size:15px;
  position:absolute;
  top:0px;
  left:30px;
  color:blue;
  background-color:rgba(255,255,180,0.7);
  padding:5px;
  border-radius:5px;
}

#divmenu .line {
  font-family: Verdana, "Bitstream Vera Sans", "Lucida Sans", sans-serif;
  font-weight:bold;
  font-size:15px;
  text-align:center;
  position:absolute;
  width:100px;
  left:5px;
  color:blue;
  padding:5px;
  display:none;
  border-radius:5px;
}

#divmenu.open .line {
  display:block;
}
#divmenu.open .line:hover {
  background-color: rgba(255,230,90,1);
  cursor:pointer;
}